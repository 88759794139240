import { Controller } from "stimulus"
import { get } from "@rails/request.js"
import "form-request-submit-polyfill"


export default class RetailPricingController extends Controller {
  
  static targets = ["materialType", "jewelryType", "shape", "price"]
  
  
  connect() {
  }
  
  
  async updatePrice() {
    let jewelryType = this.jewelryTypeTarget.selectedOptions[0].innerHTML
    let materialType = this.materialTypeTarget.selectedOptions[0].innerHTML
    let shape = this.shapeTarget.selectedOptions[0].innerHTML
    let response = await get(`/retail_products/find_price?jewelryType=${jewelryType}&materialType=${materialType}&shape=${shape}`, {
      responseKind: "json"
    })
    console.log(jewelryType)
    console.log(materialType)
    console.log(shape)
    let price_data = await response.json
    console.log(price_data)
    this.evaluate_response(price_data)
  }
  
  
  evaluate_response(price_data) {
    if (price_data.price) {
      this.display_price(`${price_data.price}`)
    } else {
      this.display_price("Nincs ilyen ár a memóriában")
    }
  }
  
  
  display_price(message) {
    this.priceTarget.innerHTML = `${message}`
  }
  
  
  
}


