import { Controller } from "stimulus"
import { get } from "@rails/request.js"
import "form-request-submit-polyfill"


export default class ShapeCategoriesController extends Controller {
  
  static targets = ["shapeCategory", "shapes", "submitButton"]
  
  
  connect() {
    let shapeCategory = this.shapeCategoryTarget.selectedOptions[0].value
    this.get_data(shapeCategory)
  }
  
  changeCategory(event) {
    let shapeCategory = event.target.selectedOptions[0].value
    this.get_data(shapeCategory)
  }
    
    
  async get_data(shapeCategory) {
    let response = await get(`/shapes/shape_category?shapeCategory=${shapeCategory}`, {
      responseKind: "json"
    })
    let shapes = await response.json
    this.manageSelect(shapes)
  }


  manageSelect(shapes){
    let numberOfItems = shapes.length
    this.shapesTarget.innerHTML = "";
    if (numberOfItems == 0) {
      this.noGroups()
    } else {
      this.updateSelect(shapes)
    }
  }


  updateSelect(shapes) {
    shapes.sort((a,b) => a.name > b.name)
    shapes.forEach((group) => {
      const option = document.createElement("option");
      option.value = group.id;
      option.innerHTML = group.name;
      this.shapesTarget.appendChild(option);
    });
    this.submitButtonTarget.disabled = false;
  }


  noGroups() {
    const option = document.createElement("option");
    option.innerHTML = "Nincs forma";
    this.shapesTarget.appendChild(option);
    this.submitButtonTarget.disabled = true;
  }
  
  
}


